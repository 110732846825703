<template>
    <v-card>
      <v-card-title>
        <v-icon color="opcion1" class="mx-2">mdi-ticket-confirmation</v-icon>
         <span>Tickets</span>
  
          <v-divider  class="mx-2" inset vertical></v-divider>
  
           <v-flex  class="mx-2"  xs5 sm4 md5 lg4>
            <v-text-field  justify-center
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Busqueda"
                                  single-line
                                  hide-details
                                ></v-text-field> 
  
            </v-flex>
  
  
               <v-spacer></v-spacer>
  
             <v-btn color="primary"  @click="dialog=true">
                <v-icon>add</v-icon>
              </v-btn>

              <v-btn color="indigo" dark class="mx-2" @click="dialogInicio=true"><v-icon>today</v-icon></v-btn>
  
      
              <v-btn color="primary" @click="listar()">
              <v-icon dark >sync</v-icon>
            </v-btn>
  
  
      </v-card-title>
  
    <v-data-table
      :headers="headers"
      :items="ticketsArray"
      :search="search"
      sort-by="fecha_reportado"
      class="elevation-1 mt-5 mx-1"
    >
         
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon  color="green" small class="mr-2" @click="editItem(item)">
         edit
        </v-icon>
        <v-icon  color="red" small @click="deleteItem(item)">
          delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">Reset</v-btn>
      </template>
  
      <template v-slot:[`item.fecha_reportado`]="{ item }">
            <span class="text-caption">{{ getDate(item.fecha_reportado) }}</span>
      </template>
  
      <template v-slot:[`item.nombre`]="{ item }">
            <span class="text-caption">{{ item.nombre }}</span>
      </template>
  
      <template v-slot:[`item.cantidad_envio_sms`]="{ item }">
          <span class="text-caption">{{ item.cantidad_envio_sms }}</span>
      </template>
  
      <template v-slot:[`item.comentario_operaciones`]="{ item }">
          <span class="text-caption">{{ item.comentario_operaciones }}</span>
      </template>

      <template v-slot:[`item.fecha_tx`]="{ item }">
          <span class="text-caption" v-if="item.fecha_tx">{{ getDate(item.fecha_tx) }}</span>
      </template>

     
  
    
  
     <template v-slot:[`item.registrado`]="{ item }">
          <span class="text-caption">{{ item.registrado }}</span>
      </template>
  
        <template v-slot:[`item.status`]="{ item }">
          <v-chip color="red" v-if="item.status==0" small>Abierto</v-chip>
          <v-chip color="success" v-if="item.status==1" small>Solucionado</v-chip>
          <v-chip color="red" class="white--text" v-if="item.status==2" x-small>Pasado a revision fisica-Vehiculo no transmite</v-chip>
      </template>
      
  
  
   
    </v-data-table>

   


    <v-dialog v-model="dialog" max-width="800px">
            <v-card>
  
                <v-toolbar color="red">
                <v-toolbar-title class="white--text">
                  {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon dark @click="dialog=false">close</v-icon>
              </v-toolbar>
  
  
  
              <v-card-text>
                <v-container>
                  <v-row>

                         <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                           <v-text-field v-model="editedItem.id_avl"  prepend-icon="qr_code" label="ID equipo"></v-text-field>
                         </v-col>

                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                           <v-text-field v-model="editedItem.placa"   :disabled="true" prepend-icon="tablet" label="Placa"></v-text-field>
                        </v-col>
  
  
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                           <v-text-field v-model="editedItem.nombre"  :disabled="true"   prepend-icon="subtitles" label="Nombre"></v-text-field>
                        </v-col>




                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                           <v-text-field v-model="editedItem.fecha_tx"  :disabled="true"   prepend-icon="today" label="fecha_tx"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                           <v-text-field v-model="editedItem.ultimo_evento" :disabled="true"  prepend-icon="label" label="ultimo_evento"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                            <v-btn
                                color="blue"
                                class="ma-2 white--text"
                                @click="validarAVL()">
                               Validar
                                <v-icon right dark>
                                    published_with_changes
                                </v-icon>
                                </v-btn>
                       
                        </v-col>


  
                         <v-col cols="12" sm="3" md="3" lg="3" xl="2">
                            <v-datetime-picker label="Fecha Abierto Ticket"  v-model="editedItem.fecha_reportado"></v-datetime-picker>
                          </v-col>
  
                     

                         <v-col v-if="editedIndex !=-1" cols="12" sm="4" md="3" lg="3" xl="3">
                            <v-datetime-picker label="Fecha Cierre Ticket" v-model="editedItem.fecha_solucion"> </v-datetime-picker>
                          </v-col>
  
  
  
                        <v-col cols="6" sm="6" md="6">
                          <v-textarea
                           v-model="editedItem.comentario_operaciones" 
                           prepend-icon="comment"
                           rows="1"
                           label="Comentario ops"></v-textarea>
                        </v-col>
  
                      
  
                         <v-col v-if="editedIndex !=-1"  cols="12" sm="12" md="12">
                          <span><v-icon class="mr-1">confirmation_number</v-icon><strong>Estado Ticket</strong></span><br>
                          <input type="radio" :value="0" v-model="editedItem.status" class="mt-2"> Abierto
                          <input type="radio" :value="1" v-model="editedItem.status" class="ml-2 mt-2"> Solucionado
                          <input type="radio" :value="2" v-model="editedItem.status" class="ml-2"> Cerrado pasa para revision fisica
                     </v-col>
  
  
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="opcion2" dark @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
      </v-dialog>
  
  
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">deseas deshabilitar Ticket?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="opcion1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogInicio" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Tickets por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Desde"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Hasta"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="red" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
    </v-dialog>


  
  </v-card>
  </template>
  
  
  <script>
  
  import axios from 'axios';
  import { mapMutations, mapState } from "vuex";

  
  
    export default {
      data: () => ({
        ticketsArray:[],
      
         search:'',
        dialog: false,
        dialogDelete: false,
        headers: [
       
          { text: 'Numero ticket', value: 'numero_ticket' },
          { text: 'ID AVL', value:'id_avl'},
          { text: 'Nombre',value:'nombre' },
          { text: 'Placa',value:'placa' },
          { text: 'Fecha reportado', value:'fecha_reportado'},  //

          { text: 'Envios', value:'cantidad_envio_sms'},

          { text:'Comentario Ops', value:'comentario_operaciones'},
          { text:'Estatus', value:'status'},

          { text:'fecha_tx', value:'fecha_tx'},

          { text: 'Acciones', value: 'actions', sortable: false },
          { text: 'Creado', value: 'registrado' }
  
        ],
  
        editedIndex: -1,
  
        
        editedItem: {
          numero_ticket:'',
          id_avl:'',
          nombre:'',
          placa:'',
        
          fecha_reportado:new Date(),
          tipo_gps:'',
          telefono:'',
          envio_reset_SMS:0,
          envio_reset_portadora:0,
          deteccion_alimentacion:0,

          fecha_tx:'',
          fecha_gps:'',
          ultimo_evento:'',

          fecha_solucion:new Date(),
          comentario_operaciones:'',
  
          status: 0, //0 -> pendiente , 1 -> solucionado, 3-> pasado a revision fisica
  
          activo: 1,
          creado:new Date()
        },
        defaultItem: {
          numero_ticket:'',
          id_avl:'',
          nombre:'',
          placa:'',
        
          fecha_reportado:new Date(),
          tipo_gps:'',
          telefono:'',
          envio_reset_SMS:0,
          envio_reset_portadora:0,
          deteccion_alimentacion:0,

          fecha_tx:'',
          fecha_gps:'',
          ultimo_evento:'',

          fecha_solucion:new Date(),
          comentario_operaciones:'',
  
          status: 0, //0 -> pendiente , 1 -> solucionado, 3-> pasado a revision fisica
  
          activo: 1,
          creado:new Date()
        },
  
  
        errorMessages: '',
        valid: true,
        rules: {
            min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
            required: (value) => !!value || "Campo Requerido.",
            email: (value) => {
                                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                return pattern.test(value) || "Invalid e-mail.";
                              },
            },
  
     

        datosVehiculoRX:{},
        datosVehiculoAVL:{},

        dialogInicio:false,

         //opciones del dialogo de fechas
         menu: false,
        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

  
  
  
      }),
  
      computed: {
  
         ...mapState(['usuario', 'token']),
  
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Ticket' : 'Edicion Ticket'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {

        this.$root.$refs.M.detenerTimer();

        let fecha=new Date();
        fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
        let dia_hoy=fecha.getDate();
        if(dia_hoy<10)dia_hoy='0'+dia_hoy;
        let mes=fecha.getMonth()+1;
        if(mes<10)mes='0'+mes;
        if(dia_hoy=='00'){dia_hoy='01';}

        this.fecha_inicio =fecha.getFullYear()+'-'+mes+'-'+dia_hoy;
        this.fecha_fin =fecha.getFullYear()+'-'+mes+'-'+dia_hoy;

        this.listar();
      
        
      },
  
      methods: {
  
         ...mapMutations(['mostrarLoading','ocultarLoading']),
  
  
          listar(){
  
            this.ticketsArray=[];
  
            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});
            let me=this;
  
            axios.post('tickets-fechas-all',
          {
            'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
            'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
          },
          config
          ).then(function(response){
  
              let respuesta=response.data;

              me.ticketsArray=respuesta;

              me.dialogInicio=false;
          
              
                
                me.ocultarLoading();
            }).catch(function(error){
              console.log(error);
            });
  
        },

    

        


         validarAVL(){


          if(this.editedItem.id_avl!=''){ 

                  let fecha_parametro = Date.now() -2400*1000; // restar 40 min
                      fecha_parametro = new Date(fecha_parametro);

                    this.mostrarLoading({titulo:'Validando AVL'});
                    let config={headers:{token:this.token}};

                    axios.get(`validar-avl/${this.editedItem.id_avl}`,
                                          config
                                        ).then(response=>{
                                        
                                          this.ocultarLoading();
                                          console.log(response.data);
                                          this.datosVehiculoAVL=response.data;

                                          this.editedItem.nombre=this.datosVehiculoAVL[0].Name;
                                          this.editedItem.id_avl=this.datosVehiculoAVL[0].ID;
                                          this.editedItem.placa=this.datosVehiculoAVL[0].Plate;

                                          this.editedItem.fecha_tx=new Date(this.datosVehiculoAVL[0].LastUpdate);
                                          this.editedItem.fecha_tx=this.ConvertirFecha(this.editedItem.fecha_tx);

                                          this.editedItem.ultimo_evento= this.datosVehiculoAVL[0].Event;

                                          this.editedItem.tipo_gps=this.datosVehiculoAVL[0].Protocol;
                                          this.editedItem.telefono=this.datosVehiculoAVL[0].Number;

                                          try {
                                                
                                                let fecha_iso= new Date(this.datosVehiculoAVL[0].LastUpdate);

                                                if(fecha_iso.getTime() > fecha_parametro.getTime()){
                                                    alert('transmitio hace:'+this.datosVehiculoAVL[0].Since);

                                                    this.dialog=false;  
                                                }else{

                                                  //no transmite
                                                  this.ValidarAlimentacion();

                                              

                                                }

                                              }catch(e) { console.log(e);}
                                        

                                          
          
                                        }).catch(e=>{
                                              console.log(e)
                                        });


             }



  
        },

        ValidarAlimentacion(){

              let textoFecha=this.datosVehiculoAVL[0].LastUpdate;
              textoFecha=textoFecha.split(" ");

              let texto_primer=textoFecha[0];
              texto_primer = texto_primer.replace("/", "-");
              texto_primer = texto_primer.replace("/", "-");


            let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'Validando alimentacion'});
      
                  axios.post('validar-alimentaciones',{ 
                                          'id_avl':this.datosVehiculoAVL[0].ID,
                                          'fecha':texto_primer
                                          },
                                        
                    config
                ).then(response=>{
                
                  console.log(response.data);

                  let respuesta=response.data;

                  if(respuesta.alimentacion==true){

                    this.editedItem.deteccion_alimentacion=true;
                    this.editedItem.status=1;

                    alert("Alimentacion descon. a las "+respuesta.fecha);
                    this.editedItem.comentario_operaciones="Alimentacion descon. a las "+respuesta.fecha;
                  }else{
                    console.log('no fue desconectado');
                    this.editedItem.comentario_operaciones="";

                    this.EnvioSMS();

                  }

                
                
                  this.ocultarLoading();
      
                }).catch(e=>{
                      console.log(e)
                });


        },





        EnvioSMS(){


                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'enviando SMS'});

                axios.post('envio-sms',{ 
                                      'telefono':this.datosVehiculoAVL[0].Number,
                                      'Protocolo':this.datosVehiculoAVL[0].Protocol,
                                      'placa':this.datosVehiculoAVL[0].Plate,
                                      'nombre':this.datosVehiculoAVL[0].Name
                                      },
                                    
                    config
                ).then(response=>{
                
                  console.log(response.data);

                  let respuesta=response.data;
                  console.log(respuesta);

                  if(respuesta.enviosms==true){
                    this.editedItem.cantidad_envio_sms=1;
                    this.editedItem.envio_reset_SMS=true;
                    this.editedItem.comentario_operaciones= this.editedItem.comentario_operaciones+" - envio sms al "+this.datosVehiculoAVL[0].Number;

                  }

                  this.EnvioEmail();

                 

                  this.ocultarLoading();

                }).catch(e=>{
                      console.log(e)
                });


        },


        EnvioEmail(){

    
                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'Envio reset linea'});

                axios.post('reset-email',{ 
                                      'telefono':this.datosVehiculoAVL[0].Number
                                      },
                                    
                    config
                    ).then(response=>{
                
                console.log(response.data);

                let respuesta=response.data;
                console.log(respuesta);

                if(respuesta.envioemail==true){
                  this.editedItem.envio_reset_portadora=true;
                    this.editedItem.comentario_operaciones= this.editedItem.comentario_operaciones+" - envio email portadora ";
                  }

                this.ocultarLoading();

              }).catch(e=>{
                    console.log(e)
              });

                



  
      },
  
    editItem(item){
  
              console.log(item);
  
              this.editedIndex = this.ticketsArray.indexOf(item);
              this.editedItem = Object.assign({}, item);
  
              console.log('indice:'+  this.editedIndex);
  
  
              this.editedItem.fecha_reportado=new Date(item.fecha_reportado);
  

              if(item.fecha_solucion && item.fecha_solucion!=''){ this.editedItem.fecha_solucion=new Date(item.fecha_solucion);}
  
           
              this.dialog=true;
        },
  
        deleteItem(item){
          this.editedIndex = this.ticketsArray.indexOf(item)
          this.editedItem._id = item._id;
          this.dialogDelete = true;
        },
  
        deleteItemConfirm(){
  
  
              let id=this.editedItem._id;
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'accediendo a datos'});
  
               axios.put(`tickets/${id}`, 
                                  {'activo':0},
                                  config
                                ).then(response=>{
                                 
                                   this.closeDelete();
                                   this.ocultarLoading();
                                   console.log(response);
                                   this.listar();
  
                                }).catch(e=>{
                                      console.log(e)
                                });
          },
  
            close(){
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        closeDelete(){
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
  
          save () {
  
            if((this.editedItem.nombre!='')&&(this.editedItem.id_avl!='')&&(this.editedItem.placa!='')&&(this.editedItem.fecha_tx!='')){
  
  
               if(this.editedIndex > -1) {
  
            
             
                 let id=this.editedItem._id;
                 this.mostrarLoading({titulo:'accediendo a datos'});
                 let config={headers:{token:this.token}};
  
                  axios.put(`tickets/${id}`,
                                { 
                                    
                                    'status':this.editedItem.status,
                                    'comentario_operaciones':this.editedItem.comentario_operaciones 
                      
                                    },
                                  config
                                ).then(response=>{
                                  this.close();
                                  this.ocultarLoading();
                                  console.log(response);
                                  this.listar();
  
                                }).catch(e=>{
                                      console.log(e)
                                });
  
                     
  
             }else{  //nuevo
  
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'accediendo a datos'});
  
               axios.post('nuevo-ticket',{ 
                      
                                      'id_avl':this.editedItem.id_avl,
                                      'nombre':this.editedItem.nombre,
                                      'placa':this.editedItem.placa,
                                      'fecha_reportado':this.editedItem.fecha_reportado,
                                      'tipo_gps':this.editedItem.tipo_gps,
                                      'telefono':this.editedItem.telefono,
                                      'envio_reset_SMS':this.editedItem.envio_reset_SMS,
                                      'cantidad_envio_sms':this.editedItem.cantidad_envio_sms,
                                      'envio_reset_portadora':this.editedItem.envio_reset_portadora,
                                      'deteccion_alimentacion':this.editedItem.deteccion_alimentacion,

                                      'fecha_tx':new Date(this.datosVehiculoAVL[0].LastUpdate),
                                      'ultimo_evento':this.editedItem.ultimo_evento,
                                      'status':this.editedItem.status,
                                      'comentario_operaciones':this.editedItem.comentario_operaciones,
                                      'registrado':this.usuario.nombre_ejecutivo+' a las:'+ this.ConvertirFecha(new Date())
                                      },
                                     
                config
            ).then(response=>{
               this.close();
              console.log(response);
              this.listar();
              this.ocultarLoading();
  
            }).catch(e=>{
                  console.log(e)
            });
  
  
  
             }
  
  
  
            }else{
  
              alert('faltan campos');
            }
           
  
  
  
          },
  
  
         getDate(evaluar) {
            let date = new Date(evaluar),
            year = date.getFullYear(),
            month = (date.getMonth() + 1).toString(),
            formatedMonth = (month.length === 1) ? ("0" + month) : month,
            day = date.getDate().toString(),
            formatedDay = (day.length === 1) ? ("0" + day) : day,
            hour = date.getHours().toString(),
            formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
            minute = date.getMinutes().toString(),
            formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
            second = date.getSeconds().toString(),
             formatedSecond = (second.length === 1) ? ("0" + second) : second;
            return formatedDay + "-" + formatedMonth + "-" + year  +' '+formatedHour+':'+formatedMinute+':'+formatedSecond;
          },
            ConvertirFecha(fecha_parametro){
  
                let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();
                return(texto);
  
              },
  
        
  
  
  
  
  
  
      }
    }
  </script>
  
  
  <style>
  .container{
       max-width: 100vw;
       padding:0px;
    }
  </style>